import Btns from "../../components/btns/Btns";
import "../../components/btns/btns_styles.css";
import { useContext, useEffect, useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import './news.css';
import newsImg from '../../image/news/news.png';
import newsImg2 from '../../image/news/news2.png';
import newsImg3 from '../../image/news/news3.png';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import RandomNews from "./RandomNews";
import AppContext from "../../context/AppContext";

export default function News() {
  const [activeButton, setActiveButton] = useState(1);
  const [newsData, setNewsData] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const { leng } = useContext(AppContext)

  const text = 'К 1 ИЮНЯ «МЕЖДУНАРОДНОМУ ДНЮ ЗАЩИТЫ ДЕТЕЙ» ПРЕПОДАВАТЕЛИ КАФЕДРЫ ПЕДАГОГИКИ И ФИЛОЛОГИИ ПО ИНИЦИАТИВЕ РОЗМЕТОВОЙ С.В., ПРЕДСЕДАТЕЛЯ КОМИТЕТА ЖЕНЩИН ТЕХНОЛОГИЧЕСКОГО УНИВЕРСИТЕТА УРГЕНЧ-РАНЧ';

  const buttons = [
    { id: 1, name_uz: 'Yangiliklar', name_ru: 'Новости', name_en: 'News', link: '/newsAll' },
    { id: 2, name_uz: 'Ommaviy tadbirlar', name_ru: 'Публичные мероприятия', name_en: 'Public Events', link: '/publicNews' },
    { id: 3, name_uz: 'Universitet', name_ru: 'Университет', name_en: 'University', link: '/newsUnversity' },
    { id: 4, name_uz: 'Xalqaro munosabatlar', name_ru: 'Международные отношения', name_en: 'International Relations', link: '/xalqaroNews' }
  ];
  
  useEffect(() => {
    const getNewsData = async () => {
      try {
        const response = await axios.get(`https://new.utu-ranch.uz/news/yangiliklar/${id}/`);
        const data = response.data;

        // If data is not an array, convert it to one
        setNewsData(Array.isArray(data) ? data : [data]);
      } catch (error) {
        setError('Failed to fetch news data.');
      }
    };

    getNewsData();
  }, [id]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!Array.isArray(newsData) || newsData.length === 0) {
    return <div>Loading...</div>;
  }


  return (
    <div className="news_page">
      <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
      {newsData.map(item => (
        <div className="news_page_main" key={item.id}>
          <div className="news_page_main_news">
            <img src={item.image || 'path/to/default/image.png'} alt={item.title_uz || 'No Image'} />
            <h4 className="title">{item[`title_${leng}`]}</h4>
            <div className="date_view">
              <p>{item.created_at}</p>
              <span><IoEyeSharp />162</span>
            </div>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[`desc_${leng}`])}} className="desc"></p>
          </div>
        </div>
      ))}
      <RandomNews />
    </div>
  );
}
