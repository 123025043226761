import { useEffect, useState } from 'react'
import './qqk.css'
import axios from 'axios';
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom';

export default function Qqk() {

    const [data, setData] = useState([]);
    const getData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/news/maqolalar/`);
        setData(response.data)
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <>
            <div className="qqk_con">
                <p className="qqkTtitle">Maqolalar</p>
                <div className="qqkBoxs">
                    {
                        data.map(item => (
                            <Link to={`/qqk/${item.id}`}>
                                <div className="qqkBox">
                                    <p className="qqkBoxs_title">{item.title_uz}</p>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.desc.substring(0, 299) + "...") }}></span>
                                    <span>{item.created_at}</span>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </>
    )
}