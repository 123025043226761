import { Link } from "react-router-dom";
import { IoEyeSharp } from 'react-icons/io5'
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import AppContext from "../../context/AppContext";


export default function RandomNews() {

    const text = 'jhksdjfkjkjsdhkjfhksf'

    const [newsData, setNewsData] = useState([])
    const getNewsData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/news/yangiliklar/`);
        setNewsData(response.data)
    }

    useEffect(() => {
        getNewsData()
    }, [])

    const getRandomSliceValue = () => {
        return Math.floor(Math.random() * (100 - 6 + 1)) + 6;
    };

    const [sliceValue, setSliceValue] = useState(getRandomSliceValue());


    useEffect(() => {
        setSliceValue(getRandomSliceValue());
    }, []);


    const { leng } = useContext(AppContext);

    return (
        <>
            <div className="moreNews">
                <div className="moreNews_header">
                    <h1>{leng == 'uz' ? "KO'PROQ YANGILIKLAR" : leng == 'ru' ? "Больше новостей" : "MORE NEWS"}</h1>
                    <Link to={"/newsAll"}><button>{leng == 'uz' ? "Yana" : leng == 'ru' ? "Все" : "All"}</button></Link>
                </div>
                <div className="moreNews_main">
                    {
                        newsData.slice(sliceValue, sliceValue + 5).map(item => (
                            <div className="moreNews_main_news">
                                <img src={item.image} alt="" />
                                <Link to={`/news/${item.id}`}><p className="title">{item[`title_${leng}`].slice(0, 100)}...</p></Link>
                                <div className="date_view">
                                    <p>{item.created_at}</p>
                                    <span><IoEyeSharp />162</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}