import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './App.css'; // Bu yerda animatsiyalar uchun CSS mavjud bo'lishi kerak
import Loading from './components/loading/Loading';
import Navbar from './components/navbar/Navbar';
import AppContext from './context/AppContext';

// Sahifalar statik import qilingan
import Home from './pages/home_page/Home';
import Menu from './pages/menu_page/Menu';
import Forma from './pages/form_page/Form';
import Enterence from './pages/enterence_page/Enterence';
import About from './pages/about_page/About';
import Magistratura from './pages/magistratura_page/Magistratura';
import Test from './pages/test/test';
import Test2 from './pages/home_page/Test2';
import News from './pages/news/News';
import Diredu from './pages/directions_educaton_page/DirEdu';
import Department from './pages/department/Department';
import DepartmentChil from './pages/department/DepartmentChil';
import Otdel from './pages/Otdels/Otdel';
import Rektorat from './pages/rektorat/Rektorat';
import Rules from './pages/rules/Rules';
import RulesEtika from './pages/rulesEtika/RulesEtika';
import Regulation from './pages/Regulation/Regulation';
import Kunduzgi from './pages/Yonalishlar/Kunduzgi';
import Sirtqi from './pages/Yonalishlar/Sirtqi';
import Mutahasislik2 from './pages/Yonalishlar/2Mutahasislik';
import Transfer from './pages/Yonalishlar/Transfer';
import Magister from './pages/magistr/Magister';
import Kvotalari from './pages/magistr/Kvotalari';
import PublicNews from './pages/news/PublicNews';
import NewsUnversity from './pages/news/NewsUnversity';
import XalqaroNews from './pages/news/XalqaroNews';
import NewsAll from './pages/news/NewsAll';
import Napravlenya from './pages/napravleniya/Napravleniya';
import Elonlar from './pages/elonlar/Elonlar';
import Qqk from './pages/QQK/Qqk';
import QqkChil from './pages/QQK/QqkChil';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={300} 
      >
        <Routes location={location}>
          <Route path='/' element={<Home />} />
          <Route path='/napravleniya' element={<Napravlenya />} />
          <Route path='/department' element={<Department />} />
          <Route path='/department/:id' element={<DepartmentChil />} />
          <Route path='/departments' element={<Otdel />} />
          <Route path='/rektorat' element={<Rektorat />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/rulesEtika' element={<RulesEtika />} />
          <Route path='/regulation' element={<Regulation />} />
          <Route path='/kunduzgi' element={<Kunduzgi />} />
          <Route path='/sirtqi' element={<Sirtqi />} />
          {/* <Route path='/mutahasislik2' element={<Mutahasislik2 />} /> */}
          {/* <Route path='/transfer' element={<Transfer />} /> */}
          <Route path='/magistratura' element={<Magister />} />
          {/* <Route path='/kvotalari' element={<Kvotalari />} /> */}
          <Route path='/university' element={<Menu />} />
          <Route path='/form' element={<Forma />} />
          <Route path='/entry' element={<Enterence />} />
          <Route path='/about' element={<About />} />
          <Route path='/sdf' element={<Magistratura />} />
          <Route path='/newsAll' element={<NewsAll />} />
          <Route path='/news/:id' element={<News />} />
          <Route path='/publicNews' element={<PublicNews />} />
          <Route path='/newsUnversity' element={<NewsUnversity />} />
          <Route path='/xalqaroNews' element={<XalqaroNews />} />
          <Route path='/test' element={<Test />} />
          <Route path='/test2' element={<Test2 />} />
          <Route path='/study' element={<Diredu />} />
          <Route path='/elonlar' element={<Elonlar />} />
          <Route path='/qqk' element={<Qqk />} />
          <Route path='/qqk/:id' element={<QqkChil />} />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  ); 
}

function App() {

  const [leng , setLeng] = useState('uz')
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3100);
  }, []);

  if (loading) {
    return <Loading />;
  }


  return (
    <div className="App">
      <BrowserRouter>
        <Navbar leng={leng} setLeng={setLeng}/>
        <AppContext.Provider value={{leng , setLeng}}>
          <AnimatedRoutes />
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;