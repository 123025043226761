import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import elonImg from './elon.jpg'
import './elon.css'



export default function Elonlar() {
    return (
        <>
            <div className="elonlar">
                <p className="elonlar_title">
                    Welcome to the International conference on “Priority directions of providing sustainable economic growth in the region” which will be held on 18th of December, 2024 in Urgench city, Uzbekistan
                </p>
                <div className="swiper_image elon_siper_con">
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={1}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                    >
                        <SwiperSlide>
                            <img src={elonImg} alt="Conference 1" />
                        </SwiperSlide> 
                        {/* <SwiperSlide>
                            <img src={elonImg} alt="Conference 1" />
                        </SwiperSlide> 
                        <SwiperSlide>
                            <img src={elonImg} alt="Conference 1" />
                        </SwiperSlide>  */}
                    </Swiper>
                </div>
            </div>
        </>
    );
}