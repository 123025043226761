import './news.css'
import Btns from "../../components/btns/Btns";
import "../../components/btns/btns_styles.css";
import { useContext, useEffect, useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import newsImg from '../../image/news/news.png'
import newsImg2 from '../../image/news/news2.png'
import newsImg3 from '../../image/news/news3.png'
import MainNews from "../../components/news_components/MainNews";
import MoreNewsMini from "../../components/news_components/MoreNewsMini";
import { Link } from "react-router-dom";
import axios from 'axios';
import AppContext from '../../context/AppContext';


export default function NewsAll() {
    // btn set
    const [activeButton, setActiveButton] = useState(1);

    const buttons = [
        {
            id: 1,
            name_uz: 'Yangiliklar',     // O'zbek tilida
            name_ru: 'Новости',         // Rus tilida
            name_en: 'News',            // Ingliz tilida
            link: '/newsAll',
        },
        {
            id: 2,
            name_uz: 'Ommaviy tadbirlar',  // O'zbek tilida
            name_ru: 'Публичные мероприятия',  // Rus tilida
            name_en: 'Public Events',          // Ingliz tilida
            link: '/publicNews',
        },
        {
            id: 3,
            name_uz: 'Universitet',     // O'zbek tilida
            name_ru: 'Университет',     // Rus tilida
            name_en: 'University',      // Ingliz tilida
            link: '/newsUnversity',
        },
        {
            id: 4,
            name_uz: 'Xalqaro munosabatlar',  // O'zbek tilida
            name_ru: 'Международные отношения',  // Rus tilida
            name_en: 'International Relations',  // Ingliz tilida
            link: '/xalqaroNews',
        }
    ]


    const [newsData, setNewsData] = useState([])
    const getNewsData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/news/yangiliklar/`);
        setNewsData(response.data)
    }

    useEffect(() => {
        getNewsData()
    }, [])

    const { leng } = useContext(AppContext);

    const [sliceValue, setSIliceValue] = useState(10)

    return (
        <div className="newsAll">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            <p className="newsAll_title">{leng == 'uz' ? "Ko'proq yangiliklar" : leng == 'ru' ? "Больше новостей" : "More news"}</p>
            <div className="newsAll_main">
                {
                    newsData.slice(0, sliceValue).map(item => (
                        <div className="moreNews_main_news">
                            <img src={item.image} alt="" />
                            <Link to={`/news/${item.id}`}><p className="title">{item[`title_${leng}`].slice(0, 100)}...</p></Link>
                            <div className="date_view">
                                <p>{item.created_at}</p>
                                <span><IoEyeSharp />162</span>
                            </div>
                        </div>
                    ))
                }
            </div>
            <button className='moreNewsBtn' onClick={() => setSIliceValue(sliceValue + 10)} >еще</button>
        </div>
    )
}