import React, { useContext } from 'react';
import './footer_styles.css';
import footerLogo from '../../image/footerLogo.svg';
import AppleStore from '../../image/applestore.svg';
import googlePlay from '../../image/googleplay.svg';
import PhoneNumber from '../../image/phoneNumber.svg';
import instaFooter from '../../image/instaFooter.svg';
import telegramFooter from '../../image/telegramFooter.svg';
import youtubeFooter from '../../image/youtubeFooter.svg';
import emailFooter from '../../image/emailFooter.svg';
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContext';

export default function Footer() {

    const { leng } = useContext(AppContext);

    const translations = {
        'ru': {
            university: "Университет",
            about: "Об Университете",
            departments: "Факультеты",
            centers: "Центры и Отделы",
            rectorate: "Ректорат",
            rules: "Правила Университета",
            ethics: "Кодекс этики",
            charter: "Устав Университета",
            abutrient: "Абитуриент",
            news: "Новости",
            publicEvents: "Публичные мероприятия",
            internationalRelations: "Международные отношения",
            fullTime: "Очные направления образования",
            correspondence: "Заочное направление оброзавания",
            onlineAdmission: "Онлайн подача документов",
            resources: "Ресурсы",
            library: "Онлайн библиотека",
        },
        'uz': {
            university: "Universitet",
            about: "Universitet haqida",
            departments: "Fakultetlar",
            centers: "Markaz va boʻlimlar",
            rectorate: "Rektorat",
            rules: "Universitet qoidalari",
            ethics: "Axloq kodeksi",
            charter: "Universitet nizomi",
            abutrient: "Abituriyent",
            news: "Yangiliklar",
            publicEvents: "Ommaviy tadbirlar",
            internationalRelations: "Xalqaro munosabatlar",
            fullTime: "Kunduzgi ta’lim yoʻnalishlari",
            correspondence: "Sirtqi taʻlim yoʻnalishlari",
            onlineAdmission: "Onlayn ariza topshirish",
            resources: "Resurslar",
            library: "Onlayn kutubxona",
        },
        'en': {
            university: "University",
            about: "About University",
            departments: "Faculties",
            centers: "Centers and other departments",
            rectorate: "University Rectorate",
            rules: "University Rules",
            ethics: "Code of Ethics",
            charter: "University charter",
            abutrient: "Applicant",
            news: "News",
            publicEvents: "Public events",
            internationalRelations: "International relations",
            fullTime: "Full-time education programs",
            correspondence: "Correspondence courses of education",
            onlineAdmission: "Online admission",
            resources: "Resources",
            library: "E-library",
        }
    }

    const currentTranslations = translations[leng];

    return (
        <footer id="footer">
            <div className="footerCon">
                <img src={footerLogo} alt="Ranch logo" className="footer-logo" />

                <h1 className="footer_h1_menu">{currentTranslations.university}</h1>
                <ul className="footerUl">
                    <Link to={'/about'}><li>{currentTranslations.about}</li></Link>
                    <Link to={'/department'}><li>{currentTranslations.departments}</li></Link>
                    <Link to={'/departments'}><li>{currentTranslations.centers}</li></Link>
                    <Link to={'/rektorat'}><li>{currentTranslations.rectorate}</li></Link>
                    <Link to={'/rules'}><li>{currentTranslations.rules}</li></Link>
                    <Link to={'/rulesEtika'}><li>{currentTranslations.ethics}</li></Link>
                    <Link to={'/regulation'}><li>{currentTranslations.charter}</li></Link>
                </ul>

                <h1 className="footer_h1_menu">{currentTranslations.news}</h1>
                <ul className="footerUl">
                    <Link to={'/newsAll'}><li>{currentTranslations.news}</li></Link>
                    <Link to={'/publicNews'}><li>{currentTranslations.publicEvents}</li></Link>
                    <Link to={'/newsUnversity'}><li>{currentTranslations.university}</li></Link>
                    <Link to={'/xalqaroNews'}><li>{currentTranslations.internationalRelations}</li></Link>
                </ul>

                <h1 className="footer_h1_menu">{currentTranslations.abutrient}</h1>
                <ul className="footerUl">
                    <Link to={'/kunduzgi'}><li>{currentTranslations.fullTime}</li></Link>
                    <Link to={'/sirtqi'}><li>{currentTranslations.correspondence}</li></Link>
                    <Link to={'https://qabul.utu-ranch.uz'}><li>{currentTranslations.onlineAdmission}</li></Link>
                </ul>

                <h1 className="footer_h1_menu">{currentTranslations.resources}</h1>
                <ul className="footerUl">
                    <Link to={'https://edu.utu-ranch.uz/'}><li>{currentTranslations.library}</li></Link>
                </ul>
            </div>
            <div className='fixpost'>
                <div className="footer-store-links">
                    <div className='btnApp'>
                        <a href="#">
                            <img src={googlePlay} alt="Google Play Store" className="store-link" />
                        </a>
                        <a href="#">
                            <img src={AppleStore} alt="Apple App Store" className="store-link" />
                        </a>
                    </div>
                    <div className="store-links2">
                        <img src={PhoneNumber} alt="phone number" />
                        <Link to={'https://www.instagram.com/utu_ranch/'}>
                            <img src={instaFooter} alt="Instagram" />
                        </Link>
                        <Link to={'https://t.me/UTU_Ranch'}>
                            <img src={telegramFooter} alt="Telegram" />
                        </Link>
                        <Link to={'https://www.youtube.com/@uturanch'}>
                            <img src={youtubeFooter} alt="YouTube" />
                        </Link>
                        <Link to={'https://mail.google.com/mail/u/0/#search/university%40utu-ranch.uz'}>
                            <img src={emailFooter} alt="Email" />
                        </Link>
                    </div>
                </div>
                <hr id='hrborder' />
            </div>
        </footer>
    );
}
