import { useEffect, useState } from 'react';
import './qqk.css';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Footer from '../../components/footer/Footer';
import { IoEyeOutline } from "react-icons/io5";

export default function QqkChil() {
    const [data, setData] = useState(null);
    const { id } = useParams();

    

    const getData = async () => {
        try {
            const response = await axios.get(`https://new.utu-ranch.uz/news/maqolalar/${id}`);
            setData(response.data);
            console.log(response.data);
        } catch (error) {
            if (error instanceof AxiosError) {
                console.error("Error fetching data:", error.message);
            } else {
                console.error("Unexpected error:", error);
            }
        }
    };

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    if (!data) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className="QqkChil">
                <p className="QqkChil_title">{data.title_uz}</p>
                <span className="QqkChil_date">{data.created_at}</span>
                <span className='QqkChil_view'><IoEyeOutline />{data.viewers}</span>
                <span className="lineQqkChil"></span>
                <p
                    className="QqkChil_text"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data.desc)
                    }}
                ></p>
            </div>
            <Footer />
        </>
    );
}
